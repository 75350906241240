<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Daftar Menara</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-warning" @click="importModal">
                      <i class="fa fa-plus-square"></i>
                      Import Menara
                  </button>
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Tambah Menara
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Pencarian Berdasarkan Perusahaan</label>
                            <select class="form-control" v-model="filters.perusahaan_id">
                                <option value="">Tampilkan Semua</option>
                                <option value="text" v-for="element in perusahaan" :key="element.id" v-bind:value="element.id">{{element.nama}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Pencarian Berdasarkan Kecamatan</label>
                            <select class="form-control" v-model="filters.kecamatan_id">
                                <option value="">Tampilkan Semua</option>
                                <option value="text" v-for="element in kecamatan" :key="element.id" v-bind:value="element.id">{{element.nama}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <table class="table table-hover table-bordered table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <!-- <th>ID</th> -->
                      <th>Pemilik Menara</th>
                      <th>Alamat Menara</th>
                      <th>Kecamatan</th>
                      <th>Lat,Long</th>
                      <th>Luas</th>
                      <th>Tinggi</th>
                      <th>Jenis Menara</th>
                      <th>Zonasi</th>
                      <th>Pengguna</th>
                      <th>Foto</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="element in items.data" :key="element.id">

                      <!-- <td>{{element.id}}</td> -->
                      <td><b>{{element.perusahaan.nama}}</b></td>
                      <td>{{element.alamat}}</td>
                      <td>{{element.kecamatan.nama}}</td>
                      <td><a target="_blank" :href="'https://maps.google.com/?q='+element.lat+','+element.long">{{element.lat}} , {{element.long}}</a></td>
                      <td>{{element.luas}} M2</td>
                      <td>{{element.tinggi}} M</td>
                      <td>{{element.jenis}} Kaki</td>
                      <td>{{element.zonasi}}</td>
                      <td>
                        <a href="" @click="$router.push({name:'pengguna',params:{menaraId: element.id}})">
                            {{element.pengguna.length}} Provider
                        </a>
                      </td>
                      <td>
                        <a href="" @click="$router.push({name:'foto',params:{menaraId: element.id}})">
                            {{element.foto.length}} Foto
                        </a>
                      </td>
                      <td>
                        
                        <a href="#" @click="editModal(element)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteData(element.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <div class="row">
                      <div class="form-group ">
                          <select class="form-control float-right" v-model="pagination.itemsPerPage" @change="getResults">
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="200">200</option>
                              <option value="500">500</option>
                          </select>
                      </div>
                      <div>
                          <pagination class="float-left" :data="items" :limit="5" @pagination-change-page="getResults"></pagination>
                      </div>
                  </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="importModel" tabindex="-1" role="dialog" aria-labelledby="importModel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Import Menara</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="importMenara()">
                    <div class="modal-body">
                        Gunakan Kode di bawah ini untuk melengkapi data excel
                        <h3>Kode Perusahaan</h3>
                        <ul>
                            <li v-for="element in perusahaan" :key="element.id">{{ element.nama }} = {{ element.id }}</li>
                        </ul>
                        <hr>
                        <h3>Kode Kecamatan</h3>
                        <ul>
                            <li v-for="element in kecamatan" :key="element.id">{{ element.nama }} = {{ element.id }}</li>
                        </ul>
                        <hr>
                        <div class="form-group">
                            <label>Format Data</label>
                            <a class="btn btn-success" href="/document/import.csv">Download</a>
                        </div>
                        <hr>
                        <div class="form-group">
                            <label>File Upload</label>
                            <input required type="file" id="file" ref="file" v-on:change="onChangeFileUpload()"/>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        <button type="submit" class="btn btn-primary">Import</button>
                    </div>
                </form>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Buat Menara Baru</h5>
                    <h5 class="modal-title" v-show="editmode">Perbarui Menara</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateData() : createData()">
                    <div class="modal-body">
                        <div class="form-group">

                            <label>Perusahaan</label>
                            <select class="form-control" v-model="form.perusahaan_id">
                              <option 
                                  v-for="x in perusahaan" :key="x.id"
                                  :value="x.id"
                                  :selected="x.id == form.perusahaan_id">{{ x.nama }}</option>
                            </select>
                            <has-error :form="form" field="perusahaan_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Alamat</label>
                            <textarea v-model="form.alamat" name="alamat"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('alamat') }"></textarea>
                            <has-error :form="form" field="alamat"></has-error>
                        </div>
                        <div class="form-group">

                            <label>Kecamatan</label>
                            <select class="form-control" v-model="form.kecamatan_id">
                              <option 
                                  v-for="x in kecamatan" :key="x.id"
                                  :value="x.id"
                                  :selected="x.id == form.kecamatan_id">{{ x.nama }}</option>
                            </select>
                            <has-error :form="form" field="kecamatan_id"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Latitude</label>
                            <input v-model="form.lat" type="text" name="lat"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('lat') }">
                            <has-error :form="form" field="lat"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Longitude</label>
                            <input v-model="form.long" type="text" name="long"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('long') }">
                            <has-error :form="form" field="long"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Luas (M2)</label>
                            <input v-model="form.luas" type="number" name="luas"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('luas') }">
                            <has-error :form="form" field="luas"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Tinggi (M)</label>
                            <input v-model="form.tinggi" type="number" name="tinggi"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('tinggi') }">
                            <has-error :form="form" field="tinggi"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Jenis (Kaki)</label>
                            <input v-model="form.jenis" type="number" name="jenis"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('jenis') }">
                            <has-error :form="form" field="jenis"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Tentukan Zonasi</label>
                            <select required class="form-control" v-model="form.zonasi">
                              <option value="pemukiman">Pemukiman</option>
                              <option value="bukanpemukiman">Bukan Pemukiman</option>
                            </select>
                            <has-error :form="form" field="zonasi"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Keterangan</label>
                            <textarea v-model="form.keterangan" name="keterangan"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('keterangan') }"></textarea>
                            <has-error :form="form" field="keterangan"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Perbarui</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Buat</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                editmode: false,
                items : {},
                form: new Form({
                    id : '',
                    perusahaan_id: '',
                    alamat: '',
                    kecamatan_id: '',
                    lat: '',
                    long: '',
                    luas: '',
                    tinggi: '',
                    jenis: '',
                    zonasi: '',
                    keterangan: ''
                }),
                filters: {
                    perusahaan_id: '',
                    kecamatan_id: ''
                },
                perusahaan: [],
                kecamatan: [],
                totalItems: 0,
                pagination: {
                    itemsPerPage: 10
                },
                file: ''
            }
        },
        watch: {
            'filters.perusahaan_id':_.debounce(function(){
                const self = this;
                self.loadData();
            },700),
            'filters.kecamatan_id':_.debounce(function(){
                const self = this;
                self.loadData();
            },700),
        },
        methods: {

          getResults(page = 1) {

              const self = this;
              self.pagination.page = page;
              self.$Progress.start();
              let params = {
                  perusahaan_id: self.filters.perusahaan_id,
                  kecamatan_id: self.filters.kecamatan_id,
                  page: self.pagination.page,
                  per_page: self.pagination.itemsPerPage
              };
              axios.get("/api/menara",{params: params}).then(function(response) {
                    self.items = response.data.data;
                    self.totalItems = response.total;
                    self.pagination.totalItems = response.total;
                });

              self.$Progress.finish();

          },
          loadData(){
            const self = this;
            self.$Progress.start();
            let params = {
                perusahaan_id: self.filters.perusahaan_id,
                kecamatan_id: self.filters.kecamatan_id,
                page: self.pagination.page,
                per_page: self.pagination.itemsPerPage
            };
            axios.get("/api/menara",{params: params}).then(function(response) {
                  self.items = response.data.data;
                  self.totalItems = response.total;
                  self.pagination.totalItems = response.total;
              });

            self.$Progress.finish();
          },
          loadPerusahaan(){
              const self = this;
              let params = {
                paginate: 'no'
              };
              axios.get('/api/perusahaan',{params: params}).then(function(response) {
                self.perusahaan = response.data.data;
              });
          },
          loadKecamatan(){
              const self = this;
              let params = {
                paginate: 'no'
              };
              axios.get('/api/kecamatan',{params: params}).then(function(response) {
                self.kecamatan = response.data.data;
              });
          },
          editModal(data){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(data);
          },
          importModal(){
              $('#importModel').modal('show');
          },
          onChangeFileUpload(){
              this.file = this.$refs.file.files[0];
          },
          importMenara(){
              return new Promise((resolve, reject) => {
                  const self = this;
                  this.$Progress.start();
                  let formData = new FormData();
                  formData.append('file', self.file);
      
                  axios.post('/api/import',
                      formData,
                      {
                      headers: {
                          'Content-Type': 'multipart/form-data'
                      }
                  }
                  ).then(function(data){
                    $('#importModel').modal('hide');

                    Toast.fire({
                            icon: 'success',
                            title: data.data.message
                        });
                    self.$Progress.finish();
                    self.loadData();
                  })
                  .catch(function(e){
                      console.log(e);
                  });
              }); 
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createData(){
              this.$Progress.start();

              this.form.post('api/menara')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateData(){
              this.$Progress.start();
              this.form.put('api/menara/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteData(id){
              Swal.fire({
                  title: 'Apakah anda yakin?',
                  text: "Anda tidak akan dapat mengembalikan data ini!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Ya, hapus data ini!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('api/menara/'+id).then(()=>{
                                      Swal.fire(
                                      'Berhasil dihapus!',
                                      'Data tersebut telah dihapus.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Gagal!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadData();
            this.loadPerusahaan();
            this.loadKecamatan();

            this.$Progress.finish();
        }
    }
</script>
