<template>
  <section class="content">
    <GmapMap
    :center="{lat:2.742936, lng:98.320370}"
    :zoom="8"
    map-type-id="terrain"
    style="width: 100%; height: 400px"
    >
    <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
      </gmap-info-window>
    <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="toggleInfoWindow(m,index)"
    />
    </GmapMap>
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">
                    <center>
                        Daftar Menara
                    </center>
                </h3>

                <div class="card-tools">
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Pencarian Berdasarkan Perusahaan</label>
                            <select class="form-control" v-model="filters.perusahaan_id">
                                <option value="">Tampilkan Semua</option>
                                <option value="text" v-for="element in perusahaan" :key="element.id" v-bind:value="element.id">{{element.nama}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Pencarian Berdasarkan Kecamatan</label>
                            <select class="form-control" v-model="filters.kecamatan_id">
                                <option value="">Tampilkan Semua</option>
                                <option value="text" v-for="element in kecamatan" :key="element.id" v-bind:value="element.id">{{element.nama}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <table class="table table-hover table-bordered table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <!-- <th>ID</th> -->
                      <th>Pemilik Menara</th>
                      <th>Alamat Menara</th>
                      <th>Kecamatan</th>
                      <th>Lat,Long</th>
                      <th>Luas</th>
                      <th>Tinggi</th>
                      <th>Jenis Menara</th>
                      <th>Zonasi</th>
                      <th>Pengguna</th>
                      <th>Foto</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="element in items.data" :key="element.id">

                      <!-- <td>{{element.id}}</td> -->
                      <td><b>{{element.perusahaan.nama}}</b></td>
                      <td>{{element.alamat}}</td>
                      <td>{{element.kecamatan.nama}}</td>
                      <td><a target="_blank" :href="'https://maps.google.com/?q='+element.lat+','+element.long">{{element.lat}} , {{element.long}}</a></td>
                      <td>{{element.luas}} M2</td>
                      <td>{{element.tinggi}} M</td>
                      <td>{{element.jenis}} Kaki</td>
                      <td>{{element.zonasi}}</td>
                      <td>
                        <a href="" @click="$router.push({name:'pengguna',params:{menaraId: element.id}})">
                            {{element.pengguna.length}} Provider
                        </a>
                      </td>
                      <td>
                        <a href="" @click="$router.push({name:'foto',params:{menaraId: element.id}})">
                            {{element.foto.length}} Foto
                        </a>
                      </td>
                      <td><button @click="newModal(element)" class="btn btn-info">Detail</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <div class="row">
                      <div class="form-group ">
                          <select class="form-control float-right" v-model="pagination.itemsPerPage" @change="getResults">
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="200">200</option>
                              <option value="500">500</option>
                          </select>
                      </div>
                      <div>
                          <pagination class="float-left" :data="items" :limit="5" @pagination-change-page="getResults"></pagination>
                      </div>
                  </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Detail</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <table>
                                <tr>
                                    <td>Perusahaan</td>
                                    <td>:</td>
                                    <td>{{ detail.perusahaan.nama }}</td>
                                </tr>
                                <tr>
                                    <td>Lokasi Menara</td>
                                    <td>:</td>
                                    <td>{{ detail.alamat }}</td>
                                </tr>
                                <tr>
                                    <td>Kecamatan</td>
                                    <td>:</td>
                                    <td>{{ detail.kecamatan.nama }}</td>
                                </tr>
                                <tr>
                                    <td>Luas</td>
                                    <td>:</td>
                                    <td>{{ detail.luas }} M2</td>
                                </tr>
                                <tr>
                                    <td>Tinggi</td>
                                    <td>:</td>
                                    <td>{{ detail.tinggi }} meter</td>
                                </tr>
                                <tr>
                                    <td>Jenis Menara</td>
                                    <td>:</td>
                                    <td>{{ detail.jenis }} kaki</td>
                                </tr>
                                <tr>
                                    <td>Zonasi</td>
                                    <td>:</td>
                                    <td>{{ detail.zonasi }}</td>
                                </tr>
                                <tr>
                                    <td>Pengguna</td>
                                    <td>:</td>
                                    <td>{{ detail.pengguna.length }}</td>
                                </tr>
                            </table>
                            
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div style="width: 100%"><iframe width="100%" height="300" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" :src='"https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q="+detail.lat+","+detail.long+"+(My%20Business%20Name)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"'></iframe></div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <!-- ======= Testimonials Section ======= -->
                            <section id="testimonials" class="testimonials">
                            <div class="container" data-aos="zoom-in">

                                <header class="section-header">
                                <h3 v-if="detail.foto.length != 0">Foto Menara</h3>
                                </header>

                                <div class="row justify-content-center">
                                <div class="col-lg-8">

                                    <div class="owl-carousel testimonials-carousel">

                                    <div class="testimonial-item" v-for="item in detail.foto" :key="item.foto">
                                        <img :src='"/storage/"+item.foto' width="100%" alt="">
                                    </div>

                                    </div>

                                </div>
                                </div>

                            </div>
                            </section><!-- End Testimonials Section -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Tutup</button>
                </div>
                </div>
            </div>
        </div>

    </div>
  </section>
</template>
<style>
    td{
        padding:5px
    }
</style>
<script>

    export default {
        data () {
            return {
                items : {},
                markers : [],
                filters: {
                    perusahaan_id: '',
                    kecamatan_id: ''
                },
                perusahaan: [],
                kecamatan: [],
                totalItems: 0,
                pagination: {
                    itemsPerPage: 10
                },
                detail: {
                    perusahaan:{
                        nama:''
                    },
                    alamat:'',
                    kecamatan:{
                        nama:''
                    },
                    luas:'',
                    tinggi:'',
                    jenis:'',
                    detail:'',
                    zonasi:'',
                    pengguna:[],
                    lat:'',
                    long:'',
                    foto: []
                },
                infoWindowPos: null,
                infoWinOpen: false,
                currentMidx: null,

                infoOptions: {
                    content: '',
                    //optional: offset infowindow so it visually sits nicely on top of our marker
                    pixelOffset: {
                    width: 0,
                    height: -35
                    }
                },
            }
        },
        watch: {
            'filters.perusahaan_id':_.debounce(function(){
                const self = this;
                self.loadData();
            },700),
            'filters.kecamatan_id':_.debounce(function(){
                const self = this;
                self.loadData();
            },700),
        },
        methods: {
            toggleInfoWindow: function(marker, idx) {
                this.infoWindowPos = marker.position;
                this.infoOptions.content = marker.infoText;

                //check if its the same marker that was selected if yes toggle
                if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
                }
                //if different marker set infowindow to open and reset current marker index
                else {
                this.infoWinOpen = true;
                this.currentMidx = idx;

                }
            },
          newModal(data){
              const self = this;
              self.detail = data;
              $('#addNew').modal('show');
          },
          async getResults(page = 1) {

              const self = this;
              self.pagination.page = page;
              self.$Progress.start();
              let params = {
                  perusahaan_id: self.filters.perusahaan_id,
                  kecamatan_id: self.filters.kecamatan_id,
                  page: self.pagination.page,
                  per_page: self.pagination.itemsPerPage,
                  order_by: 'perusahaan_id',
                order_sort: 'asc'
              };
              await axios.get("/get-menara",{params: params}).then(function(response) {
                    self.items = response.data.data;
                    self.totalItems = response.total;
                    self.pagination.totalItems = response.total;
                });
              self.serializeMap();
              self.$Progress.finish();

          },
          async loadData(){
            const self = this;
            self.$Progress.start();
            let params = {
                perusahaan_id: self.filters.perusahaan_id,
                kecamatan_id: self.filters.kecamatan_id,
                page: self.pagination.page,
                per_page: self.pagination.itemsPerPage,
                order_by: 'perusahaan_id',
                order_sort: 'asc'
            };
            await axios.get("/get-menara",{params: params}).then(function(response) {
                  self.items = response.data.data;
                  self.totalItems = response.total;
                  self.pagination.totalItems = response.total;
              });
              self.serializeMap();

            self.$Progress.finish();
          },
          loadPerusahaan(){
              const self = this;
              let params = {
                paginate: 'no'
              };
              axios.get('/get-perusahaan',{params: params}).then(function(response) {
                self.perusahaan = response.data.data;
              });
          },
          loadKecamatan(){
              const self = this;
              let params = {
                paginate: 'no'
              };
              axios.get('/get-kecamatan',{params: params}).then(function(response) {
                self.kecamatan = response.data.data;
              });
          },
          serializeMap(){
              const self = this;
              self.markers = [];
              Object.keys(self.items.data).forEach(key => {
                  let data = {
                      position : {
                        lat : parseFloat(self.items.data[key].lat),
                        lng : parseFloat(self.items.data[key].long)
                      },
                      infoText: '<strong>'+self.items.data[key].perusahaan.nama+'</strong><br>'+self.items.data[key].alamat
                  }
                  self.markers.push( data );
              });
          }

        },
        mounted() {
            
        },
        async created() {
            this.$Progress.start();

            await this.loadData();
            this.loadPerusahaan();
            this.loadKecamatan();
            this.serializeMap();
            this.$Progress.finish();
        }
    }
</script>
