<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Daftar Retribusi Menara</h3><br>
                <div class="alert alert-primary" role="alert">
                    <small>Tingkat Penggunaan Jasa : <b>{{setting.jasa}} Kali</b></small><br>
                    <small>ATK / Menara : <b>{{setting.atk | currency}}</b></small>
                </div>
                
                <div class="card-tools">
                    <button :disabled="loadingRetribusi" type="button" class="btn btn-sm btn-primary" @click="newModal()">
                        <i class="fa fa-print"></i>
                        Cetak SKRD
                    </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Pencarian Berdasarkan Perusahaan</label>
                            <select class="form-control" v-model="filters.perusahaan_id">
                                <option value="">Tampilkan Semua</option>
                                <option value="text" v-for="element in perusahaan" :key="element.id" v-bind:value="element.id">{{element.nama}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>Pencarian Berdasarkan Kecamatan</label>
                            <select class="form-control" v-model="filters.kecamatan_id">
                                <option value="">Tampilkan Semua</option>
                                <option value="text" v-for="element in kecamatan" :key="element.id" v-bind:value="element.id">{{element.nama}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <table class="table table-hover table-bordered table-striped" style="font-size:11px">
                  <thead class="thead-dark">
                    <tr>
                      <th>No.</th>
                      <th>Wajib Retribusi</th>
                      <th>Alamat Objek Retribusi</th>
                      <th>Kecamatan</th>
                      <th>Lat,Long</th>
                      <th>Luas</th>
                      <th>Tinggi</th>
                      <th>Jenis Menara</th>
                      <th>Zonasi</th>
                      <th>Pengguna</th>
                      <th>Jarak Tempuh (Kategori)</th>
                      <th>Nilai Retribusi</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(element, index) in items" :key="element.id">
                        <!-- <td>{{element.id}}</td> -->
                        <td>{{index+1}}</td>
                        <td>{{element.perusahaan.nama}}</td>
                        <td>{{element.alamat}}</td>
                        <td>{{element.kecamatan.nama}}</td>
                        <td><a target="_blank" :href="'https://maps.google.com/?q='+element.lat+','+element.long">{{element.lat}} , {{element.long}}</a></td>
                        <td>
                            {{element.luas}} M2<br> <b>[ {{element.koef_pemanfaatan_ruang}} ]</b>
                        </td>
                        <td>
                            {{element.tinggi}} M<br> <b>[ {{element.koef_tinggi}} ]</b>
                        </td>
                        <td>
                            {{element.jenis}} Kaki<br> <b>[ {{element.koef_jenis}} ]</b>
                        </td>
                        <td>
                            {{element.zonasi}}<br> <b>[ {{element.koef_zonasi}} ]</b>
                        </td>
                        <td>
                            <a href="" @click="$router.push({name:'pengguna',params:{menaraId: element.id}})">
                                {{element.pengguna.length}} Provider
                            </a><br> <b>[ {{element.koef_pengguna}} ]</b>
                        </td>
                        <td>
                            {{element.kecamatan.kategori.nama}}<br> <b>[ {{element.koef_jarak}} ]</b>
                        </td>
                        <td>
                            <b>{{ element.retribusi | currency}}</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="11" align="center"><span style="font-size:13px; font-weight:bold">Total Retribusi untuk {{totalMenara}} Menara</span></td>
                        <td><span style="font-size:13px; font-weight:bold">{{ totalRetribusi | currency}}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <div class="row">
                  </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

    <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Tentukan Lembar yang akan digenerate</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="skrd()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Lembar ke ?</label>
                            <select required class="form-control" v-model="lembar">
                                <option value="1">Lembar 1</option>
                                <option value="2">Lembar 2</option>
                                <option value="3">Lembar 3</option>
                                <option value="4">Lembar 4</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        <button type="submit" class="btn btn-primary">Buat</button>
                    </div>
                </form>
                </div>
            </div>
        </div>

    </div>
    <div v-if="loadingRetribusi" style="
        position: absolute !important;
        z-index:9999 !important; 
        background-color:rgb(255 255 255 / 0.72) !important; 
        width:100% !important; 
        height:100% !important;
        left: 0px;
        top: 0px;"
        >
        <span>
            <center style="margin-top:25% !important">
                <div class="alert alert-success" role="alert">
                <i class="fas fa-cog fa-spin" style="font-size:30px;color:red"></i><br>
                <b>Sedang Menggenerate SKRD Serta Melakukan Perhitungan Retribusi<br>Sistem mungkin membutuhkan waktu yang lama<br>Mohon menunggu...</b>
                </div>
            </center>
        </span>
    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                items : {},
                filters: {
                    perusahaan_id: '',
                    kecamatan_id: ''
                },
                perusahaan: [],
                kecamatan: [],
                totalItems: 0,
                pagination: {
                    page:1,
                    itemsPerPage: 10
                },
                setting:{
                  jasa: 2,
                  atk:100000
                },
                totalMenara:0,
                totalRetribusi:0,
                loadingRetribusi: false,
                lembar: ''
            }
        },
        watch: {
            'filters.perusahaan_id':_.debounce(function(){
                const self = this;
                self.loadData();
            },700),
            'filters.kecamatan_id':_.debounce(function(){
                const self = this;
                self.loadData();
            },700),
        },
        methods: {
          newModal(){
              self.lembar = '';
              $('#addNew').modal('show');
          },
          skrd(){
              const self = this;
              self.$Progress.start();
              self.loadingRetribusi = true;
              let arr = {
                  'data' : self.items,
                  'lembar' : self.lembar
              };
              axios({
                    url: '/api/skrd',
                    method: 'POST',
                    responseType: 'blob',
                    data: arr
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fileLink = document.createElement('a');
   
                     fileLink.href = fileURL;
                     fileLink.setAttribute('download', 'skrd.pdf');
                     document.body.appendChild(fileLink);
   
                     fileLink.click();
                     self.loadingRetribusi = false;
                     self.$Progress.finish();
                });
          },
          retribusi(){
            const self = this;
            self.totalMenara = 0;
            self.totalRetribusi = 0;
            $.each(self.items, function(index, element) {
                self.totalMenara++;
                self.totalRetribusi = self.totalRetribusi + element.retribusi;
            });
          },
          async getResults(page = 1) {

              const self = this;
              self.pagination.page = page;
              self.$Progress.start();
              let params = {
                  perusahaan_id: self.filters.perusahaan_id,
                  kecamatan_id: self.filters.kecamatan_id,
                  paginate: 'no',
                  order_by: 'perusahaan_id',
                  order_sort: 'asc'
              };
              await axios.get("/api/menara-retribusi",{params: params}).then(function(response) {
                    self.items = response.data.data;
                    self.totalItems = response.total;
                    self.pagination.totalItems = response.total;
                });
            self.retribusi();
              self.$Progress.finish();

          },
          async loadData(){
            const self = this;
            self.$Progress.start();
            let params = {
                perusahaan_id: self.filters.perusahaan_id,
                kecamatan_id: self.filters.kecamatan_id,
                paginate: 'no',
                order_by: 'perusahaan_id',
                order_sort: 'asc'
            };
            await axios.get("/api/menara-retribusi",{params: params}).then(function(response) {
                  self.items = response.data.data;
                  self.totalItems = response.total;
                  self.pagination.totalItems = response.total;
              });
            self.retribusi();
            self.$Progress.finish();
          },
          loadPerusahaan(){
              const self = this;
              let params = {
                paginate: 'no'
              };
              axios.get('/api/perusahaan',{params: params}).then(function(response) {
                self.perusahaan = response.data.data;
              });
          },
          loadKecamatan(){
              const self = this;
              let params = {
                paginate: 'no'
              };
              axios.get('/api/kecamatan',{params: params}).then(function(response) {
                self.kecamatan = response.data.data;
              });
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadData();
            this.loadPerusahaan();
            this.loadKecamatan();

            this.$Progress.finish();
        }
    }
</script>
