<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Daftar Kategori</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Tambah Kategori
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <!-- <th>ID</th> -->
                      <th>Nama</th>
                      <th>Nilai</th>
                      <th>Jumlah Kecamatan</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="element in data.data" :key="element.id">

                      <!-- <td>{{element.id}}</td> -->
                      <td>{{element.nama}}</td>
                      <td>{{element.nilai | currency}}</td>
                      <td>{{element.kecamatan.length}} Kecamatan</td>
                      <td>
                        
                        <a href="#" @click="editModal(element)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteData(element.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="data" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Buat Kategori Baru</h5>
                    <h5 class="modal-title" v-show="editmode">Perbarui Kategori</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateData() : createData()">
                    <div class="modal-body">
                        <!-- <div class="form-group">
                            <label>Nama</label>
                            <input v-model="form.nama" type="text" name="nama"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nama') }">
                            <has-error :form="form" field="nama"></has-error>
                        </div> -->
                        <div class="form-group">
                            <label>Tentukan Kategori</label>
                            <select required class="form-control" v-model="form.nama">
                              <option value="A">A</option>
                              <option value="B">B</option>
                              <option value="C">C</option>
                              <option value="D">D</option>
                            </select>
                            <has-error :form="form" field="nama"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Nilai</label>
                            <input v-model="form.nilai" type="number" name="nilai"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('nilai') }">
                            <has-error :form="form" field="nilai"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Perbarui</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Buat</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                editmode: false,
                data : {},
                form: new Form({
                    id : '',
                    nama: '',
                    nilai: ''
                }),
            }
        },
        methods: {

          getResults(page = 1) {

              this.$Progress.start();
              
              axios.get('/api/kategori?page=' + page).then(({ data }) => (this.data = data.data));

              this.$Progress.finish();
          },
          loadData(){

            // if(this.$gate.isAdmin()){
              axios.get("/api/kategori").then(({ data }) => (this.data = data.data));
            // }
          },
          editModal(data){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(data);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createData(){
              this.$Progress.start();

              this.form.post('/api/kategori')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateData(){
              this.$Progress.start();
              this.form.put('/api/kategori/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteData(id){
              Swal.fire({
                  title: 'Apakah anda yakin?',
                  text: "Anda tidak akan dapat mengembalikan data ini!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Ya, hapus data ini!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/kategori/'+id).then(()=>{
                                      Swal.fire(
                                      'Berhasil dihapus!',
                                      'Data tersebut telah dihapus.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Gagal!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadData();

            this.$Progress.finish();
        }
    }
</script>
