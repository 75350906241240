<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Daftar Pengguna Pada Menara {{menara.perusahaan.nama}} di {{menara.alamat}}</h3>

                <div class="card-tools">
                  
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Tambah Pengguna
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <!-- <th>ID</th> -->
                      <th>Provider</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="element in items.data" :key="element.id">

                      <!-- <td>{{element.id}}</td> -->
                      <td>{{element.provider.nama}}</td>
                      <td>
                        
                        <a href="#" @click="editModal(element)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteData(element.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <div class="row">
                      <div class="form-group ">
                          <select class="form-control float-right" v-model="pagination.itemsPerPage" @change="getResults">
                              <option value="1">1</option>
                              <option value="5">5</option>
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                          </select>
                      </div>
                      <div>
                          <pagination class="float-left" :data="items" :limit="5" @pagination-change-page="getResults"></pagination>
                      </div>
                  </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editmode">Buat Pengguna Baru</h5>
                    <h5 class="modal-title" v-show="editmode">Perbarui Pengguna</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <form @submit.prevent="editmode ? updateData() : createData()">
                    <div class="modal-body">
                        <div class="form-group">

                            <label>Provider</label>
                            <select class="form-control" v-model="form.provider_id">
                              <option 
                                  v-for="x in provider" :key="x.id"
                                  :value="x.id"
                                  :selected="x.id == form.provider_id">{{ x.nama }}</option>
                            </select>
                            <has-error :form="form" field="provider_id"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        <button v-show="editmode" type="submit" class="btn btn-success">Perbarui</button>
                        <button v-show="!editmode" type="submit" class="btn btn-primary">Buat</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>

    export default {
        props: {
            propMenaraId: {
                required: true
            }
        },
        data () {
            return {
                editmode: false,
                items : {},
                form: new Form({
                    id : '',
                    provider_id: '',
                    menara_id: this.propMenaraId
                }),
                provider: [],
                menara : {},
                totalItems: 0,
                pagination: {
                    itemsPerPage: 10
                },
            }
        },
        methods: {

          getResults(page = 1) {

              const self = this;
              self.pagination.page = page;
              self.$Progress.start();
              let params = {
                  menara_id: self.propMenaraId,
                  page: self.pagination.page,
                  per_page: self.pagination.itemsPerPage
              };
              axios.get("/api/pengguna",{params: params}).then(function(response) {
                    self.items = response.data.data;
                    self.totalItems = response.total;
                    self.pagination.totalItems = response.total;
                });

              self.$Progress.finish();
          },
          loadData(){
            const self = this;
            self.$Progress.start();
            let params = {
                menara_id: self.propMenaraId,
                page: self.pagination.page,
                per_page: self.pagination.itemsPerPage
            };
            axios.get("/api/pengguna",{params: params}).then(function(response) {
                  self.items = response.data.data;
                  self.totalItems = response.total;
                  self.pagination.totalItems = response.total;
              });

            self.$Progress.finish();
          },
          loadMenara(id){
              const self = this;
              axios.get('/api/menara/'+id).then(function(response) {
                self.menara = response.data.data;
              });
          },
          loadProvider(){
              const self = this;
              let params = {
                paginate: 'no'
              };
              axios.get('/api/provider',{params: params}).then(function(response) {
                self.provider = response.data.data;
              });
          },
          editModal(data){
              this.editmode = true;
              this.form.reset();
              $('#addNew').modal('show');
              this.form.fill(data);
          },
          newModal(){
              this.editmode = false;
              this.form.reset();
              $('#addNew').modal('show');
          },
          createData(){
              this.$Progress.start();

              this.form.post('/api/pengguna')
              .then((data)=>{
                if(data.data.success){
                  $('#addNew').modal('hide');

                  Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                  this.$Progress.finish();
                  this.loadData();

                } else {
                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });

                  this.$Progress.failed();
                }
              })
              .catch(()=>{

                  Toast.fire({
                      icon: 'error',
                      title: 'Some error occured! Please try again'
                  });
              })
          },
          updateData(){
              this.$Progress.start();
              this.form.put('/api/pengguna/'+this.form.id)
              .then((response) => {
                  // success
                  $('#addNew').modal('hide');
                  Toast.fire({
                    icon: 'success',
                    title: response.data.message
                  });
                  this.$Progress.finish();
                      //  Fire.$emit('AfterCreate');

                  this.loadData();
              })
              .catch(() => {
                  this.$Progress.fail();
              });

          },
          deleteData(id){
              Swal.fire({
                  title: 'Apakah anda yakin?',
                  text: "Anda tidak akan dapat mengembalikan data ini!",
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  cancelButtonColor: '#3085d6',
                  confirmButtonText: 'Ya, hapus data ini!'
                  }).then((result) => {

                      // Send request to the server
                        if (result.value) {
                              this.form.delete('/api/pengguna/'+id).then(()=>{
                                      Swal.fire(
                                      'Berhasil dihapus!',
                                      'Data tersebut telah dihapus.',
                                      'success'
                                      );
                                  // Fire.$emit('AfterCreate');
                                  this.loadData();
                              }).catch((data)=> {
                                  Swal.fire("Gagal!", data.message, "warning");
                              });
                        }
                  })
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.loadData();
            this.loadMenara(this.propMenaraId);
            this.loadProvider();

            this.$Progress.finish();
        }
    }
</script>
