export default [
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/products', component: require('./components/product/Products.vue').default },

    { path: '/kategori', component: require('./components/kategori/Kategori.vue').default },
    { path: '/kecamatan', component: require('./components/kecamatan/Kecamatan.vue').default },
    { path: '/perusahaan', component: require('./components/perusahaan/Perusahaan.vue').default },
    { path: '/provider', component: require('./components/provider/Provider.vue').default },
    { 
        path: '/menara', 
        name:'menara',
        component: require('./components/menara/Menara.vue').default
    },
    { 
        path: '/menara/:menaraId/pengguna',
        name:'pengguna',
        component: require('./components/pengguna/Pengguna.vue').default,
        props: (route) => ({propMenaraId: route.params.menaraId}),
    },
    { 
        path: '/menara/:menaraId/foto',
        name:'foto',
        component: require('./components/foto/Foto.vue').default,
        props: (route) => ({propMenaraId: route.params.menaraId}),
    },
    { 
        path: '/retribusi', 
        name:'retribusi',
        component: require('./components/retribusi/Retribusi.vue').default
    },
    { 
        path: '/koordinat', 
        name:'koordinat',
        component: require('./components/menara/Koordinat.vue').default
    },
    { path: '/product/tag', component: require('./components/product/Tag.vue').default },
    { path: '/product/category', component: require('./components/product/Category.vue').default },
    { path: '*', component: require('./components/NotFound.vue').default }
];
