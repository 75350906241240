<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">

          <div class="col-12">
        
            <div class="card">
              <div class="card-header">
                <h3 class="card-title">Daftar Menara<br><small>Diurut Berdasarkan Jarak Terdekat Dengan Koordinat yang Ditentukan</small></h3>
                
                <div class="card-tools">
        
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive">
                <div class="row">
                    <div class="col-sm-5">
                        <div class="form-group">
                            <label>Latitude</label>
                            <input v-model="form.lat" type="text" name="lat"
                                class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="form-group">
                            <label>Longitude</label>
                            <input v-model="form.long" type="text" name="long"
                                class="form-control">
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <div class="form-group">
                        <button @click="loadData()" class="btn btn-primary btn-block">Telusuri</button>
                        </div>
                    </div>
                </div>
                <table class="table table-hover table-bordered table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <!-- <th>ID</th> -->
                      <th>Perusahaan</th>
                      <th>Alamat</th>
                      <th>Kecamatan</th>
                      <th>Lat,Long</th>
                      <th>Luas</th>
                      <th>Tinggi</th>
                      <th>Jenis Menara</th>
                      <th>Zonasi</th>
                      <th>Pengguna</th>
                      <th>Foto</th>
                      <th>Jarak</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="element in items" :key="element.id">

                      <!-- <td>{{element.id}}</td> -->
                      <td><b>{{element.perusahaan.nama}}</b></td>
                      <td>{{element.alamat}}</td>
                      <td>{{element.kecamatan.nama}}</td>
                      <td><a target="_blank" :href="'https://maps.google.com/?q='+element.lat+','+element.long">{{element.lat}} , {{element.long}}</a></td>
                      <td>{{element.luas}} M2</td>
                      <td>{{element.tinggi}} M</td>
                      <td>{{element.jenis}} Kaki</td>
                      <td>{{element.zonasi}}</td>
                      <td>
                        <a href="" @click="$router.push({name:'pengguna',params:{menaraId: element.id}})">
                            {{element.pengguna.length}} Provider
                        </a>
                      </td>
                      <td>
                        <a href="" @click="$router.push({name:'foto',params:{menaraId: element.id}})">
                            {{element.foto.length}} Foto
                        </a>
                      </td>
                      <td>
                        {{element.distance.toFixed(2)}} KM
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

    </div>
  </section>
</template>

<script>

    export default {
        data () {
            return {
                items : {},
                form : {
                    lat : '',
                    long : ''
                }
            }
        },
        methods: {
          loadData(){
            const self = this;
            self.$Progress.start();
            let params = {
                lat: self.form.lat,
                long: self.form.long
            };
            axios.get("/api/nearest",{params: params}).then(function(response) {
                  self.items = response.data.data;
              });

            self.$Progress.finish();
          },

        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();

            this.$Progress.finish();
        }
    }
</script>
