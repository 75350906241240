<template>
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-success" role="alert">
                    Selamat Datang di Sistem Informasi Menara Telekomunikasi (SIMENTEL) Pemerintah Kabupaten Dairi
                    </div>
                </div>
            </div>
            <div class="row ">
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box">
                        <span class="info-box-icon bg-info elevation-1"><i class="fas fa-archive"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Kecamatan</span>
                            <span class="info-box-number">
                            {{ kecamatan}}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-danger elevation-1"><i class="fas fa-building"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Perusahaan</span>
                            <span class="info-box-number">
                            {{ perusahaan}}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->

                <!-- fix for small devices only -->
                <div class="clearfix hidden-md-up"></div>

                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-success elevation-1"><i class="fas fa-list"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Provider</span>
                            <span class="info-box-number">
                            {{ provider}}
                            </span>
                        </div>
                        <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
                <!-- /.col -->
                <div class="col-12 col-sm-6 col-md-3">
                    <div class="info-box mb-3">
                        <span class="info-box-icon bg-warning elevation-1"><i class="fas fa-broadcast-tower"></i></span>

                        <div class="info-box-content">
                            <span class="info-box-text">Menara</span>
                            <span class="info-box-number">
                            {{ menara }}
                            </span>
                        </div>
                    <!-- /.info-box-content -->
                    </div>
                    <!-- /.info-box -->
                </div>
            <!-- /.col -->
            </div>
            <!-- /.row -->

            <div class="row">
                <div class="col-12">
                    <div class=" h-100 d-flex justify-content-center align-items-center" style="margin-top:40px">
                    <div>
                        <h1>
                            <center>
                            Total Retribusi Menara Telekomunikasi<br>Pada Lingkungan Pemerintah Kabupaten Dairi<br>
                            <span v-if="loadingTotalRetribusi">
                                <span class="badge badge-warning">
                                    Sedang Menghitung...
                                </span>
                            </span>
                            <span v-else>
                                <span class="badge badge-success">
                                    {{ totalRetrtibusi | currency }}
                                </span>
                            </span>
                            </center>
                        </h1>
                    </div>
                    </div>
                </div>
            </div>
            <!-- /.row -->
        </div><!--/. container-fluid -->
    </section>
</template>

<script>

    export default {
        data () {
            return {
                menara : 0,
                kecamatan : 0,
                perusahaan : 0,
                provider : 0,
                totalRetrtibusi : 0,
                loadingTotalRetribusi : false
            }
        },
       
        methods: {
          loadTotalRetribusi(){
            const self = this;
            self.loadingTotalRetribusi = true;
            axios.get("/api/total-retribusi").then(function(response) {
                self.totalRetrtibusi = response.data.data;
                self.loadingTotalRetribusi = false;
            });

          },
          loadMenara(){
            const self = this;
            axios.get("/api/count/menara").then(function(response) {
                  self.menara = response.data.data;
              });

          },
          loadKecamatan(){
            const self = this;
            axios.get("/api/count/kecamatan").then(function(response) {
                  self.kecamatan = response.data.data;
              });

          },
          loadPerusahaan(){
            const self = this;
            axios.get("/api/count/perusahaan").then(function(response) {
                  self.perusahaan = response.data.data;
              });

          },
          loadProvider(){
            const self = this;
            axios.get("/api/count/provider").then(function(response) {
                  self.provider = response.data.data;
              });

          },
        },
        mounted() {
            
        },
        created() {
            this.loadMenara();
            this.loadKecamatan();
            this.loadPerusahaan();
            this.loadProvider();
            this.loadTotalRetribusi();
        }
    }
</script>
