<template>
  <section id="call-to-action" class="call-to-action">
    <div class="container" data-aos="zoom-out">
        <div class="row">
        <div class="col-lg-9 text-center text-lg-left">
            <h3 class="cta-title">Menara Telekomunikasi</h3>
            <p class="cta-text"> Temukan daftar menara telekomunikasi yang berdiri dilingkungan Pemerintah Kabupaten Dairi.</p>
        </div>
        <div class="col-lg-3 cta-btn-container text-center">
            <div class="row counters" data-aos="fade-up" data-aos-delay="100">

            <div class="col-lg-12 col-12 text-center">
            <h3 class="cta-title" data-toggle="counter-up">{{menara}}</h3>
            <p class="cta-text">Menara Yang Terdaftar</p>
            </div>

            </div>
        </div>
        </div>

    </div>
    </section><!--  End Call To Action Section -->
</template>

<script>

    export default {
        data () {
            return {
                menara : 0
            }
        },
       
        methods: {
          loadData(){
            const self = this;
            axios.get("/count-menara").then(function(response) {
                  self.menara = response.data.data;
              });

          },
        },
        mounted() {
            
        },
        created() {
            this.loadData();
        }
    }
</script>
